import React, { useState } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { StaticQuery, graphql } from "gatsby"

import {
  Box,
  Container,
  H1,
  H2,
  H6,
  SEO,
  Text,
  Flex,
  Select,
  Button,
} from "components"

const CustomInput = ({ error, ...props }) => {
  return (
    <Box position="relative">
      {error && (
        <Box
          position="absolute"
          top={0}
          height="100%"
          left={0}
          width="12px"
          bg="#D14C4C"
          css={css({ transform: "translateX(-100%)" })}
        />
      )}
      <input
        css={css({
          flex: 1,
          width: "100%",
          minWidth: 0,
          m: 0,
          py: "16px",
          px: "20px",
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: "16px",
          fontWeight: "body",
          lineHeight: "body",
          color: error ? "#D14C4C" : "text",
          bg: "muted",
          appearance: "none",
          "&:focus": { outline: 0 },
          "::placeholder": {
            color: "#999",
          },
        })}
        {...props}
      />
    </Box>
  )
}

const CustomTextarea = ({ error, ...props }) => {
  return (
    <Box position="relative" bg="muted">
      {error && (
        <Box
          position="absolute"
          top={0}
          height="100%"
          left={0}
          width="12px"
          bg="#D14C4C"
          css={css({ transform: "translateX(-100%)" })}
        />
      )}
      <textarea
        {...props}
        css={css({
          display: "block",
          width: "100%",
          m: 0,
          p: 3,
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: "16px",
          fontWeight: "body",
          lineHeight: "body",
          color: error ? "#D14C4C" : "text",
          bg: "muted",
          ":focus": { outline: 0 },
          "::placeholder": {
            color: "#999",
          },
        })}
      />
    </Box>
  )
}

const CustomSelect = ({ error, ...props }) => {
  return (
    <Box position="relative" bg="muted">
      {error && (
        <Box
          position="absolute"
          top={0}
          height="100%"
          left={0}
          width="12px"
          bg="#D14C4C"
          css={css({ transform: "translateX(-100%)" })}
        />
      )}
      <Select
        {...props}
        css={css({
          color: error ? "#D14C4C" : "text",
        })}
      />
    </Box>
  )
}

function BuilderContactPage() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [zip, setZip] = useState("")
  const [projectType, setProjectType] = useState("")
  const [professionType, setProfessionType] = useState("")
  const [message, setMessage] = useState("")

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [stateError, setStateError] = useState(false)
  const [zipError, setZipError] = useState(false)
  const [countryError, setCountryError] = useState(false)
  const [projectError, setProjectError] = useState(false)
  const [professionError, setProfessionErrror] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const encode = function(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = function(e) {
    e.preventDefault()
    let error = false
    setFirstNameError(false)
    setLastNameError(false)
    setEmailError(false)
    setAddressError(false)
    setCityError(false)
    setStateError(false)
    setZipError(false)
    setCountryError(false)
    if (!firstName) {
      error = true
      setFirstNameError(true)
    }
    if (!lastName) {
      error = true
      setLastNameError(true)
    }
    if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      error = true
      setEmailError(true)
    }
    if (!address) {
      error = true
      setAddressError(true)
    }
    if (!city) {
      error = true
      setCityError(true)
    }
    if (!state) {
      error = true
      setStateError(true)
    }
    if (!country) {
      error = true
      setCountryError(true)
    }
    if (!zip) {
      error = true
      setZipError(true)
    }
    if (!projectType) {
      error = true
      setProjectError(true)
    }
    if (!professionType) {
      error = true
      setProfessionErrror(true)
    }
    if (!error) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "branchContactGeneral",
          firstName: firstName,
          lastName: lastName,
          email: email,
          address: address,
          city: city,
          state: state,
          country: country,
          zip: zip,
          projectType: projectType,
          professionType: professionType,
          message: message,
        }),
      })
        .then(() => {
          setSuccess(true)
        })
        .catch(error => {
          setFailure(true)
        })
    }
  }
  return (
    <StaticQuery
      query={graphql`
        {
          page: datoCmsContact {
            builderTrendTitle
            builderTrendSubtitle
          }
        }
      `}
      render={data => (
        <>
          <SEO
            title={`Branch - Contact - Falk`}
            description={`Reach out and get in touch with the team at Falkbuilt. Contact your branch online now to make an appointment for a consultation.`}
          />
          <Box as="section" pt={[96, 120]}>
            <Container pt={[5, 6]}>
              <H1 children={data.page.builderTrendTitle} textAlign="center" />
              {data.page.builderTrendSubtitle && (
                <Text
                  children={data.page.builderTrendSubtitle}
                  mt={[3, 4]}
                  fontFamily="body"
                  fontSize={[4, 5]}
                  textAlign="center"
                />
              )}
            </Container>
            <Box pt={5} pb={[0, 5]}>
              <Container>
                <Box maxWidth={768} mx={"auto"} bg="#fff" p={[3, 4]}>
                  {!success && !failure && (
                    <Box
                      as={"form"}
                      onSubmit={handleSubmit}
                      name="branchContactGeneral"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                    >
                      <input type="hidden" name="bot-field" />
                      <H6 children={`Your Information`} mb={3} />
                      <Box
                        css={css({
                          "> *:not(:last-child)": {
                            mb: [3, 4],
                          },
                        })}
                      >
                        <Box>
                          <CustomInput
                            placeholder={`First Name`}
                            name="firstName"
                            value={firstName}
                            onChange={event => {
                              setFirstName(event.target.value)
                            }}
                            error={firstNameError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`Last Name`}
                            name="lastName"
                            value={lastName}
                            onChange={event => {
                              setLastName(event.target.value)
                            }}
                            error={lastNameError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`Email`}
                            name="email"
                            value={email}
                            onChange={event => {
                              setEmail(event.target.value)
                            }}
                            error={emailError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`Address`}
                            name="address"
                            value={address}
                            onChange={event => {
                              setAddress(event.target.value)
                            }}
                            error={addressError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`City`}
                            name="city"
                            value={city}
                            onChange={event => {
                              setCity(event.target.value)
                            }}
                            error={cityError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`State/Province`}
                            name="state"
                            value={state}
                            onChange={event => {
                              setState(event.target.value)
                            }}
                            error={stateError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`Country`}
                            name="country"
                            value={country}
                            onChange={event => {
                              setCountry(event.target.value)
                            }}
                            error={countryError}
                          />
                        </Box>
                        <Box>
                          <CustomInput
                            placeholder={`Zip/Postal Code`}
                            name="zip"
                            value={zip}
                            onChange={event => {
                              setZip(event.target.value)
                            }}
                            error={zipError}
                          />
                        </Box>
                        <Box>
                          <H6 children={`Project Type`} mb={3} />
                          <CustomSelect
                            value={projectType}
                            name="projectType"
                            onChange={event => {
                              setProjectType(event.target.value)
                            }}
                            error={projectError}
                          >
                            <option value={``} disabled={true}>
                              Select a project type
                            </option>
                            <option children={`Education`} />
                            <option children={`Commercial + Office`} />
                            <option children={`Healthcare`} />
                            <option children={`Other`} />
                          </CustomSelect>
                        </Box>
                        <Box>
                          <H6 children={`Profession`} mb={3} />
                          <CustomSelect
                            value={professionType}
                            name="professionType"
                            onChange={event => {
                              setProfessionType(event.target.value)
                            }}
                            error={professionError}
                          >
                            <option value={``} disabled={true}>
                              Select a profession
                            </option>
                            <option children={`Architect`} />
                            <option children={`Interior Design`} />
                            <option children={`General Contractor`} />
                          </CustomSelect>
                        </Box>
                        <Box>
                          <CustomTextarea
                            placeholder={"Tell us about your project"}
                            name="message"
                            rows="7"
                            value={message}
                            onChange={event => {
                              setMessage(event.target.value)
                            }}
                          />
                        </Box>
                      </Box>
                      <Button
                        children={`Submit`}
                        type={"Submit"}
                        variant="reverse"
                        mt={4}
                      />
                    </Box>
                  )}
                  {success && (
                    <Box>
                      <H2 children={`Thank you!`} textAlign="center" />
                      <Text
                        mt={3}
                        lineHeight={1.5}
                        children={`We will be in touch as soon as poosible. In the meantime why not check out some of the cool things Falk has to offer!`}
                        textAlign="center"
                      />
                      <Flex justifyContent="center">
                        <Button
                          children={`Gallery`}
                          variant="reverse"
                          mt={4}
                          as={Link}
                          to={"/gallery/"}
                        />
                      </Flex>
                    </Box>
                  )}
                  {failure && (
                    <Box maxWidth={520}>
                      <H2 children={`We had a problem!`} textAlign="center" />
                      <Text
                        mt={3}
                        lineHeight={1.5}
                        children={`Try refreshing this page and trying again, and if this issue persists, send us an email!`}
                        textAlign="center"
                      />
                    </Box>
                  )}
                </Box>
              </Container>
            </Box>
          </Box>
        </>
      )}
    />
  )
}

export default BuilderContactPage
